<template>
	<main>
		<HeaderTab
			:title="$t('global.titre_embryon_management')"
			:swiper_tabs="swiper_tabs"
		/>

		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<router-view></router-view>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	name: 'EmbryonManagement',
	computed: {
		swiper_tabs() {
			return [{
					id: 'embryon_available',
					label: 'gynecologie.embryon.available',
					active: false,
					href: 'embryonAvailable'
				}, {
					id: 'embryon_season',
					label: 'gynecologie.embryon.season',
					active: false,
					href: 'embryonSeason'
				}
			]
		}
	},
	components: {
		HeaderTab: () => import('@/components/HeaderTab'),
	}
}
</script>
